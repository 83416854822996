

$(document).ready(function(){
   

    if($('.admin-edit-locations').length > 0){
        $('.admin-edit-locations .map-wrapper .cell').on('click', function(event){
            event.preventDefault();
            var column = $(this).attr('data-column')
            var row = $(this).attr('data-row')
            if($(this).hasClass('active')){
                $(this).removeClass('active')
                $('input[type="hidden"][name="'+row+'--'+column+'"]').val(0)
            }else {
                $(this).addClass('active')
                $('input[type="hidden"][name="'+row+'--'+column+'"]').val(1)
            }
        })
    }

    if($('.home-index').length > 0){
        $('.type-select-wrapper .selected-type').on('click', function(event){
            $(this).parent().toggleClass('active');
        })

        $('.type-select-wrapper .dropdown .type-wrapper').on('click', function(event){
            event.preventDefault()
            _id = $(this).attr('data-type');
            $('input[name="type"]').val(_id);
            $('.type-select-wrapper .selected-type').html($(this).html())
            $('.type-select-wrapper').removeClass('active');
        })

        $('.pokemon-select-wrapper .selected-pokemon').on('click', function(event){
            $(this).parent().toggleClass('active');
        })

        $('.pokemon-select-wrapper .dropdown .pokemon-wrapper').on('click', function(event){
            event.preventDefault()
            _id = $(this).attr('data-pokemon');
            $('input[name="search_pokemon"]').val(_id);
            $('.pokemon-select-wrapper .selected-pokemon').html($(this).html())
            $('.pokemon-select-wrapper').removeClass('active');
        })
        $('.map-wrapper .cell').on('click', function(event){
            event.preventDefault();
            $('.map-wrapper .cell').removeClass('active')
            if(!$(this).hasClass('active')){
                $(this).addClass('active')
            }
        })
    }
    
})

